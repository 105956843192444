@tailwind base;
  @tailwind components;
  @tailwind utilities;

  @layer base {
    :root {
      --background: 0 0% 100%;
      --foreground: 222.2 84% 4.9%;

      --card: 0 0% 100%;
      --card-foreground: 222.2 84% 4.9%;

      --popover: 0 0% 100%;
      --popover-foreground: 222.2 84% 4.9%;

      --primary: 222.2 47.4% 11.2%;
      --primary-foreground: 210 40% 98%;

      --secondary: 210 40% 96.1%;
      --secondary-foreground: 222.2 47.4% 11.2%;

      --muted: 210 40% 96.1%;
      --muted-foreground: 215.4 16.3% 46.9%;

      --accent: 210 40% 96.1%;
      --accent-foreground: 222.2 47.4% 11.2%;

      --destructive: 0 84.2% 60.2%;
      --destructive-foreground: 210 40% 98%;

      --border: 214.3 31.8% 91.4%;
      --input: 214.3 31.8% 91.4%;
      --ring: 222.2 84% 4.9%;

      --grey-border: 0 0% 96.1%;

      --radius: 0.5rem;

      --gradient-start-green: 143 73.3% 20.6%;   
      --gradient-mid-mint: 116 20% 52.9%;
      --gradient-end-white: 146 9.6% 85.7%;

      --dark-green-background: 143 74.8% 20.2%;

      --accent-soft: 210 22.2% 96.5%;

      --info-soft: 41 82.4% 93.3%;

      --light-grey-border: 210 7.7% 89.8%; 


      --text-primary-black: 0, 0%, 0%;
      --text-secondary-grey: 210 1.9% 58.4%;
      --text-light-grey: 210 2.3% 66.3%;
      --background-primary-white: 0, 0%, 100%;

      --inaccessible-grey: 240 6.7% 97.1%;

      --lightest-grey: 240 14.3% 97.3%;

      --signinfields: 210 1.9% 58.4%;

      --signinring: 143, 75%, 20%;
    }

    textarea {
      font-family: var(--font-inter) !important;
    }
    

    .dark {
      --background: 222.2 84% 4.9%;
      --foreground: 210 40% 98%;

      --card: 222.2 84% 4.9%;
      --card-foreground: 210 40% 98%;

      --popover: 222.2 84% 4.9%;
      --popover-foreground: 210 40% 98%;

      --primary: 210 40% 98%;
      --primary-foreground: 222.2 47.4% 11.2%;

      --secondary: 217.2 32.6% 17.5%;
      --secondary-foreground: 210 40% 98%;

      --muted: 217.2 32.6% 17.5%;
      --muted-foreground: 215 20.2% 65.1%;

      --accent: 217.2 32.6% 17.5%;
      --accent-foreground: 210 40% 98%;

      --destructive: 0 62.8% 30.6%;
      --destructive-foreground: 210 40% 98%;

      --border: 217.2 32.6% 17.5%;
      --input: 217.2 32.6% 17.5%;
      --ring: 212.7 26.8% 83.9%;
      --green-ring: 143 75% 20%;
    }

    .custom-login-box {
      width: 448px;
      height: 538px;
      background-color: #ffffff;
      border-radius: 0.5rem;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      clip-path: path("M0 32C0 20.7989 0 15.1984 2.18 10.9202 4.097 7.1569 7.157 4.0973 10.92 2.1799 15.198 0 20.799 0 32 0H125.789C130.643 0 133.07 0 135.356.5447 137.382 1.0276 139.321 1.8242 141.102 2.9057 143.11 4.1255 144.836 5.8317 148.288 9.2442L181.936 42.5119C185.388 45.9244 187.113 47.6306 189.122 48.8504 190.903 49.9319 192.841 50.7286 194.868 51.2115 197.154 51.7561 199.581 51.7561 204.434 51.7561H242.422 416C427.201 51.7561 432.802 51.7561 437.08 53.936 440.843 55.8535 443.903 58.9131 445.82 62.6763 448 66.9546 448 72.5551 448 83.756V506C448 517.201 448 522.802 445.82 527.08 443.903 530.843 440.843 533.903 437.08 535.82 432.802 538 427.201 538 416 538H32C20.799 538 15.198 538 10.92 535.82 7.157 533.903 4.097 530.843 2.18 527.08 0 522.802 0 517.201 0 506V32Z");
    }

    .netic-logo {
      background-color: rgba(0, 0, 0, 0.4);
      width: 62px; /* Updated width */
      height: 17px; /* Updated height */
      clip-path: path("M0 16.7129H1.9638V6.33655H4.20814C4.50873 6.33655 4.74919 6.52111 4.86942 6.8082L8.23594 15.0929C8.63672 16.0977 9.53846 16.7129 10.6005 16.7129H14.8287V4.40893H12.8649V14.7853H10.6406C10.34 14.7853 10.0995 14.6007 9.97931 14.3136L6.6128 6.02895C6.21202 5.02413 5.31028 4.40893 4.24822 4.40893H0V16.7129ZM23.5656 17C26.1306 17 28.0944 16.2618 29.5171 14.9083L28.2346 13.4934C27.0123 14.6827 25.329 15.0724 23.5656 15.0724C21.0808 15.0724 19.3575 13.5549 19.117 11.1556H30.1383V10.5199C30.1383 6.64415 27.3529 4.08082 23.5656 4.08082C19.7983 4.08082 17.033 6.66466 17.033 10.5199C17.033 14.3957 19.7783 17 23.5656 17ZM19.1972 9.39204C19.618 7.30036 21.2612 6.00844 23.5656 6.00844C25.85 6.00844 27.5533 7.25935 27.9942 9.39204H19.1972ZM37.1319 16.7129H40.1978V14.7853H36.691C35.9295 14.7853 35.4887 14.3341 35.4887 13.5549V6.37756H40.3982V4.44994H35.4887V1.37394H33.565V4.44994H31.1403V6.37756H33.5249V13.0217C33.5249 15.1749 35.0278 16.7129 37.1319 16.7129ZM45.5682 16.7129H47.532V7.32087C47.532 5.63932 46.3697 4.44994 44.7266 4.44994H41.8009V6.37756H44.7666C45.2676 6.37756 45.5682 6.68516 45.5682 7.19783V16.7129ZM45.4279 2.56333H47.7925V0H45.4279V2.56333ZM56.5294 17C58.894 17 60.5171 16.2823 61.8798 14.9083L60.6574 13.5754C59.7156 14.6417 58.4732 15.0724 56.5294 15.0724C53.8041 15.0724 51.8403 13.3703 51.8403 10.5814C51.8403 7.77201 53.7841 6.00844 56.5294 6.00844C58.393 6.00844 59.4751 6.45959 60.7376 7.81303L62 6.41858C60.7576 4.98311 58.914 4.08082 56.5294 4.08082C52.702 4.08082 49.7964 6.66465 49.7964 10.5814C49.7964 14.4777 52.722 17 56.5294 17Z")
    }

    
    .sidebar-icon {
      width: 20px;
      height: 20px;
    }
    
    .sidebar-icon-active {
      background-color: #ffffff;
    }
    
    .sidebar-icon-active .icon-path {
      fill: hsl(var(--dark-green-background));
    }
    
    .sidebar-icon-inactive {
      background-color: transparent;
    }
    
    .sidebar-icon-inactive .icon-path {
      fill: transparent;
      stroke: #ffffff;
      stroke-width: 1px;
    }
    
    .n-logo {
      background-color: #ffffff;
      clip-path: path("M0 11.7296H1.90045V1.96365H4.0724C4.36328 1.96365 4.59599 2.13735 4.71235 2.40756L7.97026 10.2049C8.35811 11.1506 9.23077 11.7296 10.2586 11.7296H14.3504V0.149414H12.4499V9.9154H10.2973C10.0065 9.9154 9.77376 9.74169 9.6574 9.47149L6.39948 1.67414C6.01164 0.728425 5.13898 0.149414 4.11118 0.149414H0V11.7296Z");
      width: 14.35px;
      height: 11.58px;
    }


    .send-icon {
      background-color: #ffffff;
      width: 16px;
      height: 16px;
      clip-path: path("M2.87003 2.29788C2.73905 2.27733 2.60495 2.29187 2.48142 2.34002C2.35788 2.38817 2.24932 2.46821 2.16679 2.57198C2.08426 2.67575 2.03071 2.79955 2.01161 2.93076C1.9925 3.06196 2.00852 3.19589 2.05803 3.31888L3.39003 6.62388C3.46464 6.80888 3.59287 6.96734 3.75824 7.0789C3.9236 7.19045 4.11856 7.25 4.31803 7.24988H8.25003C8.44895 7.24988 8.63971 7.3289 8.78036 7.46955C8.92102 7.61021 9.00003 7.80097 9.00003 7.99988C9.00003 8.1988 8.92102 8.38956 8.78036 8.53021C8.63971 8.67087 8.44895 8.74988 8.25003 8.74988H4.31803C4.11873 8.74997 3.92399 8.80961 3.75882 8.92115C3.59365 9.03269 3.46557 9.19104 3.39103 9.37588L2.05803 12.6809C2.00835 12.8038 1.99216 12.9378 2.01112 13.069C2.03007 13.2003 2.08349 13.3242 2.16592 13.4281C2.24836 13.5319 2.35686 13.6121 2.48038 13.6604C2.60389 13.7087 2.738 13.7233 2.86903 13.7029C7.13625 13.039 11.1573 11.2762 14.537 8.58788C14.6255 8.51766 14.697 8.42834 14.7462 8.32661C14.7953 8.22488 14.8208 8.11336 14.8208 8.00038C14.8208 7.8874 14.7953 7.77589 14.7462 7.67416C14.697 7.57242 14.6255 7.48311 14.537 7.41288C11.1573 4.72452 7.13725 2.96178 2.87003 2.29788Z");
    }
    


    .custom-scrollbar::-webkit-scrollbar {
      width: 5px;
    }
    
    .custom-scrollbar::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    
    .custom-scrollbar::-webkit-scrollbar-thumb {
      background: #888;
    }
    
    .custom-scrollbar::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
    
    .custom-scrollbar {
      scrollbar-width: thick;
      scrollbar-color: #e4e4e4 #f9f9f9;
    }





  }

  @layer utilities {
    .hide-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }
    .hide-scrollbar::-webkit-scrollbar {
      display: none;  /* Chrome, Safari and Opera */
    }
  }

  @layer base {
    * {
      @apply border-border;
    }
    body {
      @apply bg-background text-foreground;
    }
  }
